import React, { Component } from 'react'
import { Page } from 'lib'

import Landing from './sections/_landing'
import Testimonials from './sections/_testimonials'
import Services from './sections/_services'
import Portfolio from './sections/_portfolio'
import Pricing from './sections/_pricing'
import Fab from './sections/_fab'
import { graphql } from 'gatsby'

export default class Homepage extends Component {
  constructor(props) {
    super(props)
    this.updateHeaderStatus = this.updateHeaderStatus.bind(this)
    this.state = {
      transparentHeader: true,
      fabVisible: false,
    }
  }

  componentDidMount() {
    this.updateHeaderStatus()
    window.addEventListener('scroll', this.updateHeaderStatus)

    // dumb hack because otherwise anchor scroll from other pages doesn't work -.-
    if (window.location.hash) {
      const { hash } = window.location
      window.location.hash = ''
      window.location.hash = hash
    }

    // set up smooth anchor link scroll - only for the homepage
    if (typeof window !== 'undefined') {
      // eslint-disable-next-line global-require
      require('smooth-scroll')('a[href*="#"]', {
        speed: 500,
        speedAsDuration: true,
      })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.updateHeaderStatus)
  }

  updateHeaderStatus() {
    this.setState({
      transparentHeader: (window.pageYOffset + 80) < window.innerHeight,
      fabVisible: window.pageYOffset * 4 > window.innerHeight,
    })
  }

  render() {
    // const blogPostsPreview = this.props.data.allMarkdownRemark.edges.slice(0, 3)
    return (
      <Page fullWidth disableTopPadding transparentHeader={this.state.transparentHeader}>
        <Landing/>
        <span id='services'/>
        <Services/>
        <Testimonials/>
        <span id='portfolio'/>
        <Portfolio/>
        <span id='pricing'/>
        <Pricing/>
        <Fab visible={this.state.fabVisible}/>
      </Page>
    )
  }
}

export const query = graphql`
query {
  allMarkdownRemark
  (sort: { fields: [frontmatter___date], order: DESC })
  {
    totalCount
    edges {
      node {
        id
        excerpt(pruneLength: 400)
        frontmatter {
          title
          subtitle
        }
        fields {
          slug
        }
        timeToRead
      }
    }
  }
}
`
