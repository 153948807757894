import React from 'react'
import { makeStyles } from '@material-ui/styles'
import Fab from '@material-ui/core/Fab'

export default function Contact(props) {
  const styles = useStyles(props)
  if (!props.visible) return null
  return (
    <Fab
      disableRipple
      color='secondary'
      variant='extended'
      href='/proposal'
      className={`${styles.fab} ${!props.visible && styles.fabHidden}`}>
      Contact us →
    </Fab>
  )
}

const useStyles = makeStyles(theme => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 1000,
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    textTransform: 'none',
    background: theme.palette.primary.light,
    color: 'white',
    [`&:hover`]: {
      background: theme.palette.primary.main,
    },
  },
  fabHidden: {
    display: 'none',
  },
}))
