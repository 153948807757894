/* eslint-disable react/display-name */
import React, { useState } from 'react'
import { makeStyles } from '@material-ui/styles'
import { View, Text, CenterWrapper, CTAButton, Button, Image, SectionHeader, SectionWrapper } from 'lib'
import { navigate } from 'gatsby'
import Grid from '@material-ui/core/Grid'
import ReactDevicePreview from 'react-device-preview'
import Slider from 'react-slick'
import './_portfolio.css'
import { useTheme } from '@material-ui/core/styles'
import VisibilitySensor from 'react-visibility-sensor'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { TierItem } from './_pricing'

const apps = (styles) => {
  return {
    direction: {
      title: 'Direction',
      button: () => { return <Button target='_blank' href='https://www.direction.coach/' className={styles.caseStudyButton}>Visit Direction's website</Button> },
      mobileAttributes: [
        'AI coach and habit tracker',
        'Powered by GPT-4 AI',
        'Sleek and efficient UI',
        'Secure and scalable backend',
      ],
      images: [
        'assets/portfolio/direction1.jpg',
        'assets/portfolio/direction2.png',
        'assets/portfolio/direction3.png',
        'assets/portfolio/direction4.png',
      ],
    },
    bearable: {
      title: 'Bearable',
      button: () => { return <Button target='_blank' href='https://www.bearable.app/' className={styles.caseStudyButton}>Visit Bearable's website</Button> },
      mobileAttributes: [
        'Health tracking for iOS and Android',
        '500,000+ users worldwide',
        'Advanced stats and visualisations',
      ],
      images: [
        'assets/portfolio/bearable1.jpg',
        'assets/portfolio/bearable2.jpg',
        'assets/portfolio/bearable3.jpg',
        'assets/portfolio/bearable4.jpg',
      ],
    },
    gridicity: {
      title: 'Gridicity',
      button: () => { return <Button target='_blank' href='https://gridicity.co.uk/' className={styles.caseStudyButton}>Visit Gridicity's website</Button> },
      mobileAttributes: [
        'AI for electric vehicle charging',
        'Project funded by Innovate UK',
      ],
      images: [
        'assets/portfolio/gridicity1.jpg',
        'assets/portfolio/gridicity2.jpg',
      ],
    },
    neurometrics: {
      title: 'Neurometrics-Diab',
      button: () => { return <Button onClick={() => navigate('/blog/neurometrics-diab/')} className={styles.caseStudyButton}>See case study</Button> },
      mobileAttributes: [
        'Computer vision technology',
        'iOS and Android app for patients',
        'Web interface and database for health care professionals',
      ],
      images: [
        'assets/portfolio/neurometrics1.jpg',
        'assets/portfolio/neurometrics3.jpg',
        'assets/portfolio/neurometrics4.jpg',
      ],
    },
    easyticketai: {
      title: 'Easy Ticket AI',
      button: () => { return <Button target='_blank' href='https://easyticket.ai' className={styles.caseStudyButton}>Visit website</Button> },
      mobileAttributes: [
        'Drafts responses to customer support emails',
        'Powered by GPT-4 AI',
        'Seamlessly integrates with support platforms like Zendesk',
      ],
      images: [
        'assets/portfolio/easyticketai1.jpg',
        'assets/portfolio/easyticketai2.jpg',
      ],
    },
  }
}

function AppDescription(props) {
  const styles = useStyles()

  return (
    <Grid item md={6} sm={12} className={styles.appDescriptionWrapper}>
      <View style={styles.appDescription}>

        <Text variant={'h5'} className={styles.descriptionTitle} gutterBottom>{props.meta.title}</Text>
        <View style={styles.bulletWrapper}>
          {props.meta.mobileAttributes.map((a, i) => <TierItem key={i} item={a}/>)}
        </View>

        <View platform={'desktop'}>
          {renderButton(props.meta)}
        </View>

      </View>
    </Grid>
  )
}

function BackgroundCircle(props) {
  const styles = useStyles()
  const className = styles.ball
  return (
    <View style={styles.ballWrapper}>
      <View>
        <View className={className}/>
      </View>
    </View>
  )
}

function Demo(props) {
  const styles = useStyles(props)
  const settings = {
    dots: false,
    adaptiveHeight: true,
    autoplay: true,
    autoplaySpeed: 2500,
  }

  return (
    <Grid item md={6} sm={12}>
      <View className={styles.deviceSectionWrapper}>
        <BackgroundCircle/>
        <View className={styles.deviceWrapper}>
          <ReactDevicePreview
            device='iphonex'
            scale={0.8}
            className={styles.deviceFrame}
          >
            <Slider {...settings}>
              {props.images.map((image, index) => (
                <Image src={image} key={index} className={styles.deviceImage}/>
              ))}
            </Slider>
          </ReactDevicePreview>
        </View>
      </View>
    </Grid>
  )
}

function renderButton(props) {
  const styles = useStyles(props)
  return <View style={styles.buttonWrapper}><props.button/></View>
}

export default function Portfolio(props) {
  const styles = useStyles(props)
  const theme = useTheme()
  const data = apps(styles)
  const [visible, setVisible] = useState(false)

  const onChange = (arg) => { if (!visible) setVisible(arg) }

  return (
    <CenterWrapper>
      <VisibilitySensor onChange={onChange} partialVisibility={true}>
        <SectionWrapper>
          <SectionHeader
            title={'See some of our past projects'}
            subtitle={`We've worked with startups and established companies alike.`}
          />
          <View platform={'desktop'}>

            {Object.values(data).map((app, index) => (
              <View style={`${styles.appRow} ${index % 2 && styles.appRowReverse}`} key={index}>
                <AppDescription meta={app}/>
                <Demo visible={visible} images={app.images}/>
              </View>
            ))}

          </View>

          <View style={styles.center} platform={'mobile'}>
            {Object.values(data).map((app, index) => (
              <View key={index}>
                <AppDescription meta={app}/>
                <Demo images={app.images}/>
                {renderButton(app)}
              </View>
            ))}
          </View>

        </SectionWrapper>
      </VisibilitySensor>
    </CenterWrapper>
  )
}

const scale = 0.6
const device = { width: 427, height: 864 } // iPhone X from react-device-preview
const ballsize = device.height * scale * 0.8

const useStyles = makeStyles(theme => ({
  appDescriptionWrapper: {
    ...theme.centralise,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  appDescription: {
    width: device.width * scale * 1.25,
    [theme.breakpoints.down('xs')]: {
      width: device.width * scale,
    },
  },
  descriptionTitle: {
    textAlign: 'center',
    marginBottom: theme.spacing(2.5),
  },
  ball: {
    ...theme.circle(ballsize),
    background: theme.palette.primary.gradient,
    boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.2)',
  },
  ballWrapper: {
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('sm')]: {
      overflow: 'hidden',
      width: '100%',
    },
  },
  deviceSectionWrapper: {
    ...theme.centralise,
  },
  deviceWrapper: {
    height: device.height * scale,
    width: device.width * scale,
  },
  deviceImage: {
    width: 375,
  },
  caseStudyButton: {
    flex: 'inherit',
    margin: 'inherit',
    marginLeft: 'auto',
    marginRight: 'auto',
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(2),
    },
  },
  appRow: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(8),
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  appRowReverse: {
    flexDirection: 'row-reverse',
  },
  center: {
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    display: 'flex',
  },
  buttonWrapper: {
    // flex: 1,
    // display: 'flex',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
    },
  },
  bulletWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignSelf: 'center',
    [theme.breakpoints.down('xs')]: {
      width: device.width * scale,
    },
  },
}))
