import React, { useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/styles'
import { View, Text, Image } from 'lib'
import Carousel from 'react-material-ui-carousel'

const testimonials = [
  {
    text: 'These people know what they are doing and are both efficient and clever and made a good partner. I wouldn’t hesitate to recommend TechnoQuest.',
    by: '- John Simpson, CEO, Skyrocket Phytopharma (UK) Ltd',
  },
  {
    text: 'TechnoQuest made me feel like my options were limitless in terms of what could be achieved, which was critical to me as we were creating something very unique.',
    by: '- James Saady, Bearable founder',
  },
]

export default function Testimonials() {
  const styles = useStyles()

  return (
    <View style={styles.bottom}>

      <View style={styles.sloganWrapper}>

        <Carousel
          animation='slide'
          indicators={false}
          navButtonsAlwaysVisible
          navButtonsProps={{
            style: {
              backgroundColor: 'transparent',
              margin: 0,
            },
          }}
          className={styles.carousel}
        >

          {testimonials.map((t, i) => (
            <>
              <Text style={styles.testimonialText}>{t.text}</Text>
              <Text style={styles.testimonialBy}>{t.by}</Text>
            </>
          ))}

        </Carousel>

      </View>
    </View>
  )
}

const styles = theme => ({
  bottom: {
    ...theme.centralise,
    backgroundColor: theme.palette.primary.main,
    minHeight: 240,
  },
  sloganWrapper: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 560,
  },
  carousel: {
    paddingLeft: 50,
    paddingRight: 50,
  },
  testimonialText: {
    marginTop: theme.spacing(2.5),
    marginBottom: theme.spacing(1.5),
    fontStyle: 'italic',
    color: 'white',
  },
  testimonialBy: {
    color: '#999999',
    textAlign: 'center',
    marginBottom: theme.spacing(1.5),
  },
})

const useStyles = makeStyles(styles)
