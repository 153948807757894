import React, { useState } from 'react'
import { useTheme, makeStyles } from '@material-ui/styles'
import { View, Text, CTAButton, Button } from 'lib'

import { TierItem } from './_pricing'

const sellingPoints = [
  'A London-based studio',
  'Creating stunning apps & websites',
  'Serving innovators worldwide',
]

export default function Landing() {
  const styles = useStyles()

  return (
    <View style={styles.wrapper}>
      <View style={styles.top}/>
      <View style={styles.bottom}>

        <View style={styles.sloganWrapper}>
          <Text variant='h4' style={styles.slogan}>
          Your product, developed with<br/><span className={styles.sloganBottom}>style and speed</span>
          </Text>

          <View style={styles.bulletWrapper}>

            {sellingPoints.map((p, i) => <TierItem key={i} item={p} darkBg/>)}

          </View>

          <View style={styles.cta}>
            <Button href='#services'>
            Our approach
            </Button>
            <CTAButton text={'Contact us ->'} className={styles.ctaButton}/>
          </View>

        </View>
      </View>
    </View>
  )
}

const styles = theme => ({
  wrapper: {
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    [theme.breakpoints.up('md')]: {
      minHeight: 700,
    },
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
  },
  top: {
    flex: 1,
    backgroundImage: `url('/images/bg.jpg')`,
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  bottom: {
    ...theme.centralise,
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
  },
  sloganWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
  },
  slogan: {
    fontWeight: 800,
    color: 'white',
    marginBottom: 0,
    lineHeight: 1.4,
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: 24,
    },
  },
  sloganBottom: {
    color: theme.palette.secondary.intense,
  },
  sloganSub: {
    marginBottom: theme.spacing(1),
    color: 'white',
    fontWeight: 400,
    [theme.breakpoints.down('sm')]: {
      marginBottom: 4,
    },
  },
  bulletWrapper: {
    marginTop: theme.spacing(3),
    alignSelf: 'center',
  },
  cta: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: theme.spacing(3),
  },
  ctaButton: {
    backgroundColor: 'transparent',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

const useStyles = makeStyles(styles)
