import React, { Component, useState } from 'react'
import { withStyles, useTheme, makeStyles } from '@material-ui/styles'
import { View, Text, CenterWrapper, Image, SectionHeader, SectionWrapper } from 'lib'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Tilt from 'react-tilt'

const imageSize = 120

const servicesData = [
  {
    title: 'Mobile & web',
    body: 'Sleek and powerful apps for all platforms.',
    image: 'assets/mobile.png',
  },
  {
    title: 'AI & automation',
    body: 'Scalable and secure self-learning systems.',
    image: 'assets/web.png',
  },
]

class Services extends Component {
  render() {
    const styles = this.props.classes
    const items = servicesData.map((item, index) => { return <ServiceItem key={index} data={item}/> })
    return (
      <CenterWrapper>
        <SectionWrapper>
          <SectionHeader
            title={'Your tech partner'}
            subtitle={`TechnoQuest provides everything you need to build a successful digital product, from design and development to global scaling.`}
          />
          <View style={styles.cardsWrapper}>
            {items}
          </View>

        </SectionWrapper>
      </CenterWrapper>
    )
  }
}

function ServiceItem({ data }) {
  const [expanded, setExpanded] = useState(false)
  const styles = useStyles()
  const theme = useTheme()
  const { title, body, image } = data
  const isSmall = useMediaQuery(theme.breakpoints.down('md'))
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'))
  const pose = (isPhone || expanded) ? 'expand' : 'init'
  const Wrapper = isPhone ? View : Tilt
  return (
    <Wrapper options={{ scale: 1.05, max: 5, speed: 3000 }}>
      <View
        onMouseEnter={() => setExpanded(true)}
        onMouseLeave={() => setExpanded(false)}
        style={styles.card}
      >
        <Image src={image} className={styles.image} resizeMode={'cover'}/>
        <Text text={title} variant={'h6'} style={styles.cardTopText} gutterBottom/>
        <Text text={body} style={styles.cardBottomText}/>
      </View>
    </Wrapper>
  )
}


const styles = theme => ({
  cardsWrapper: {
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
    flex: 1,
    display: 'flex',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    textAlign: 'center',
    justifyContent: 'center',
  },
  card: {
    // minHeight: cardHeight,
    maxWidth: theme.maxMobileWidth,
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: theme.spacing(3),
    padding: theme.spacing(3),
    boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.1)',
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
      padding: theme.spacing(2),
    },
  },
  cardTop: {
    display: 'flex',
    // flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
  },
  cardTopText: {
    marginTop: theme.spacing(2),
    color: theme.palette.primary.main,
    fontSize: 30,
    [theme.breakpoints.down('md')]: {
      fontSize: 26,
    },
  },
  cardBottom: {},
  cardBottomText: {
    // minHeight: 80,
    textAlign: 'center',
    fontSize: 16,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: 14,
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: 'calc(10px + 1.4vw)',
    },
  },
  image: {
    height: imageSize,
    width: imageSize,
    margin: theme.spacing(1),
  },
  ctaText: {
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    fontWeight: '400',
    color: '#333',
  },
  ctaLink: {
    ...theme.typography.link,
    ...theme.typography.ctaLink,
  },
})

const useStyles = makeStyles(styles)

export default withStyles(styles, { withTheme: true })(Services)
