import React from 'react'
import { View, Text, CenterWrapper, SectionHeader, SectionWrapper, CTAButton } from 'lib'
import { FaCheckCircle } from 'react-icons/fa'
import { useTheme, makeStyles } from '@material-ui/styles'

const pricingOptions = [
  {
    title: 'Tech Consulting',
    description: `Let's solve your problem with tech`,
    range: 'From £2,000 / $2,500',
    items: [
      'Technical diagnosis',
      'Solutions engineering',
      'Fast and enduring results',
    ],
  },
  {
    title: 'New Software Project',
    description: 'Design, develop and scale a winning product',
    range: 'From £7,500 / $9,000',
    mostPopular: true,
    items: [
      'iOS, Android & Web apps',
      'AI & data science',
      'Execution time from 4 weeks',
    ],
  },
  {
    title: 'Spec Ops',
    description: 'An IT project specific to your needs',
    range: 'Contact us',
    items: [
      'Complex challenges with many stakeholders',
      '... (almost) anything tech!',
    ],
  },
]

function PricingTier(props) {
  const styles = useStyles(props)
  const items = props.meta.items.map((item, index) => (
    <TierItem key={index} item={item}/>
  ))
  return (
    <View className={styles.tierWrapper}>
      <View className={`${styles.tilt} ${props.meta.mostPopular && styles.tiltPopular}`}>
        <View>
          <Text className={styles.titleText} variant={'h5'}>{props.meta.title}</Text>
          <Text variant={'subtitle1'}>{props.meta.description}</Text>
          <View className={styles.tierItemsWrapper}>
            {items}
          </View>
        </View>
        <Text variant={'h6'} style={styles.range}>{props.meta.range}</Text>
        {props.meta.mostPopular && (
          <View className={styles.tiltPopularTextWrapper}>
            <Text style={styles.tiltPopularText}>Most popular</Text>
          </View>
        ) }
      </View>
    </View>
  )
}

export function TierItem(props) {
  const styles = useStyles(props)
  const theme = useTheme()
  return (
    <View className={styles.tierItem}>
      <FaCheckCircle color={props.darkBg ? 'white' : theme.palette.primary.main} size='20' className={styles.checkIcon}/>
      <Text variant={'subtitle1'} className={`${styles.tierItemText} ${props.darkBg && styles.tierItemTextDarkBg}`}>{props.item}</Text>
    </View>
  )
}

export default function Pricing() {
  const tiers = pricingOptions.map((tier, index) => <PricingTier meta={tier} key={index}/>)
  const theme = useTheme()
  const styles = useStyles()
  return (
    <CenterWrapper>
      <SectionWrapper style={styles.sectionWrapper}>
        <SectionHeader
          title={'Pricing'}
          subtitle={`Get a rough idea below, but reach out to us for a free consultation and your custom quote.`}
        />
        <View style={styles.tiersContainer}>
          {tiers}
        </View>
        <View style={styles.ctaButtonWrapper}>
          <CTAButton text={'Contact us'}/>
        </View>
      </SectionWrapper>
    </CenterWrapper>
  )
}

const useStyles = makeStyles(theme => ({
  sectionWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  tiersContainer: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      maxWidth: theme.maxMobileWidth,
      alignItems: 'center',
      justifyContent: 'center',
    },
  },
  tierWrapper: {
    margin: theme.spacing(3),
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    display: 'flex',
    alignSelf: 'stretch',
    flex: 1,
  },
  tilt: {
    padding: theme.spacing(3),
    [theme.breakpoints.down('md')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    borderRadius: theme.spacing(2),
    boxShadow: '1px 0px 40px rgba(0, 0, 0, 0.07)',
    textAlign: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between',
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    backgroundColor: 'white',
    display: 'flex',
    flex: 1,
    position: 'relative',
  },
  tiltPopular: {
    border: `2px solid ${theme.palette.primary.light}`,
  },
  tiltPopularText: {
    color: 'white',
    backgroundColor: theme.palette.primary.light,
    borderRadius: 16,
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5),
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
    alignSelf: 'center',
  },
  tiltPopularTextWrapper: {
    position: 'absolute',
    top: -16,
    left: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
  },
  tierItemsWrapper: {
    textAlign: 'left',
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
    margin: theme.spacing(1),
  },
  tierItem: {
    marginTop: theme.spacing(0.5),
    display: 'flex',
  },
  tierItemText: {
    color: '#333',
    marginLeft: theme.spacing(2),
    fontSize: 17,
    fontWeight: '400',
    flex: 1,
    marginTop: 0,
  },
  tierItemTextDarkBg: {
    color: 'white',
  },
  checkIcon: {
    marginTop: theme.spacing(0.25),
  },
  ctaButtonWrapper: {
    marginBottom: theme.spacing(6),
  },
  ctaText: {
    textAlign: 'center',
    paddingTop: theme.spacing(8),
    fontWeight: '400',
    color: '#333',
  },
  ctaLink: {
    ...theme.typography.link,
    ...theme.typography.ctaLink,
  },
  titleText: {
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  range: {
    fontSize: 20,
  },
}))
